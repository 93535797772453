<template>
  <div id="event-plan-details">
    <v-card>
      <v-card-title class="plan-title grey lighten-2">
        {{ Plan.PlanName }} - {{ Plan.SubTotalTxt }}
      </v-card-title>

      <v-card-text class="plan-description">
        <div v-html="Plan.Content" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.prevent="closePrompt"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    Plan: {
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
#event-plan-details {
  .plan-title {
    color: blue !important;
    font-size: 20px !important;
  }
  .plan-description {
    color: black !important;
    font-size: 18px !important;
    line-height: 1.5;
    text-align: left;
    margin-left: 10px;
  }
}
</style>